import './styles/login.css'
import {Navigate, Outlet, useNavigate} from "react-router-dom";
import {ReplyChatTitle} from "./components/components";
import {$, alert, dialog} from "mdui";
import React, {useContext} from "react";
import {UserContext} from "../index";

export function Login() {
    const navigate = useNavigate();
    const {setUser} = useContext(UserContext)

    function login() {
        fetch(`/api/login/`, {
            method: "POST",
            body: JSON.stringify({
                username: $("#untf").val(), password: $("#pwdtf").val()
            })
        }).then(r => r.text()).then(r => {
            if (r === "failed") {
                alert({
                    headline: "Login", description: "Error", confirmText: "OK"
                })
            } else {
                window.login_state = r;
                setUser(r)
                navigate("/")
            }
        })
    }

    return <div className={"container"}>
        <ReplyChatTitle>Login</ReplyChatTitle>
        <mdui-card class={"login-card"}>
            <h2 style={{textAlign: "center"}}>Reply</h2>
            <mdui-text-field type="text" label="Username" style={{marginBottom: "4px"}} id={"untf"}></mdui-text-field>
            <mdui-text-field type="password" toggle-password label="Password"
                             style={{marginBottom: "4px"}} id={"pwdtf"}></mdui-text-field>
            <mdui-button full-width style={{marginBottom: "4px"}} onClick={login}>Login</mdui-button>
            <mdui-divider style={{marginBottom: "4px"}}></mdui-divider>
            <div style={{display: "flex", flexDirection: "row"}}>
                <p style={{width: "100%", margin: "auto"}}>Don't have an account?</p>
                <mdui-button variant={"text"} href={"https://reply-app.us.kg/register"}>Register</mdui-button>
            </div>
        </mdui-card>
    </div>
}

export function Logout() {
    const {setUser} = useContext(UserContext)
    setUser("guest")
    window.login_state = "guest";
    fetch(`/api/logout/`)
    return <Navigate to={"/login/"} replace={true}/>
}

export function Rights() {
    return <div>
        <Outlet/>
        <span className={"rights"}>© 2024 Reply</span>
    </div>
}