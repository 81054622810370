import {getPrivateKey, getPrivateKeyJWK, base64ToUint8Array} from "./CryptoUtils"

function ArrayBufferToBase64(buffer) {
    return Uint8ArrayToBase64(new Uint8Array(buffer))
}

function Uint8ArrayToBase64(array) {
    var binary = "";
    for (var len = array.byteLength, i = 0; i < len; i++) {
        binary += String.fromCharCode(array[i]);
    }
    return window.btoa(binary).replace(/=/g, "");
}

function base64toArrayBuffer(b64) {
    return base64ToUint8Array(b64).buffer
}

function getKeyMaterial() {
    const password = window.crypto.randomUUID();
    const enc = new TextEncoder();
    return window.crypto.subtle.importKey("raw", enc.encode(password), {name: "PBKDF2"}, false, ["deriveBits", "deriveKey"],);
}

function getUnsafeEncodeKey(keyMaterial, salt) {
    return window.crypto.subtle.deriveKey({
        name: "PBKDF2", salt, iterations: 100000, hash: "SHA-256",
    }, keyMaterial, {name: "AES-GCM", length: 256}, true, ["wrapKey", "unwrapKey"],);
}

async function wrapCryptoKey(keyToWrap) {
    const keyMaterial = await getKeyMaterial();
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const wrappingKey = await getUnsafeEncodeKey(keyMaterial, salt);
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const wrappedKey = await window.crypto.subtle.wrapKey("pkcs8", keyToWrap, wrappingKey, {
        name: "AES-GCM", iv,
    })

    return [ArrayBufferToBase64(wrappedKey), JSON.stringify(await window.crypto.subtle.exportKey("jwk", wrappingKey)), Uint8ArrayToBase64(iv)]
}

async function unwrapPrivateKey(unsafeEncodingKeyJWK, wrappedKeyB64, ivB64) {
    const unwrappingKey = await window.crypto.subtle.importKey("jwk", unsafeEncodingKeyJWK, {
        name: "AES-GCM", length: 256
    }, true, ["wrapKey", "unwrapKey"],);
    const wrappedKeyBuffer = base64toArrayBuffer(wrappedKeyB64);
    const ivBuffer = base64toArrayBuffer(ivB64);
    return await window.crypto.subtle.unwrapKey("pkcs8", wrappedKeyBuffer, unwrappingKey, {
        name: "AES-GCM", iv: ivBuffer,
    }, {
        name: "RSA-OAEP", modulusLength: 2048, publicExponent: new Uint8Array([1, 0, 1]), hash: "SHA-256",
    }, true, ["decrypt"]);
}

export async function getKeys(account) {
    if (window.localStorage.getItem("keys") === null) {
        return null
    } else if (JSON.parse(window.localStorage.getItem("keys"))[account] === undefined) {
        return null
    } else {
        const keys = JSON.parse(window.localStorage.getItem("keys"))[account]
        const results = await fetch("/api/getAES/").then(res => res.json())
        console.log({
            publicKey: keys.publicKey,
            privateKey: await getPrivateKeyJWK(await unwrapPrivateKey(JSON.parse(results.unsafeEncodingKey), keys.privateKey, results.iv))
        })
        return {
            publicKey: keys.publicKey,
            privateKey: await getPrivateKeyJWK(await unwrapPrivateKey(JSON.parse(results.unsafeEncodingKey), keys.privateKey, results.iv))
        }
    }
}

export async function setKeys(account, publicKeyJWK, privateKeyJWK) {
    let keys
    if (window.localStorage.getItem("keys") === null) {
        keys = {}
    } else {
        keys = JSON.parse(window.localStorage.getItem("keys"))
    }
    const [privateKeyB64, unsafeEncodingKeyJWK, ivB64] = await wrapCryptoKey(await getPrivateKey(privateKeyJWK))
    fetch("/api/setAES", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            unsafeEncodingKey: unsafeEncodingKeyJWK,
            iv: ivB64,
            publicKey: publicKeyJWK
        }),
    })
    keys[account] = {
        publicKey: publicKeyJWK, privateKey: privateKeyB64
    }
    window.localStorage.setItem("keys", JSON.stringify(keys))
}

export async function getOriginalKeys(account) {
    if (window.localStorage.getItem("keys") === null) {
        return null
    } else if (JSON.parse(window.localStorage.getItem("keys"))[account] === undefined) {
        return null
    } else {
        return JSON.parse(window.localStorage.getItem("keys"))[account]
    }
}

export async function setOriginalKeys(account, publicKeyJWK, wrappedPrivateKey) {
    let keys
    if (window.localStorage.getItem("keys") === null) {
        keys = {}
    } else {
        keys = JSON.parse(window.localStorage.getItem("keys"))
    }
    keys[account] = {
        publicKey: publicKeyJWK, privateKey: wrappedPrivateKey
    }
    window.localStorage.setItem("keys", JSON.stringify(keys))
}