import {alert, dialog, prompt} from "mdui";
import {loading_remove, ReplyChatTitle, show_loading} from "./components/components";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../index";

import {Peer} from "peerjs"
import {genKey, getPublicKey, encrypt, decrypt, getPublicKeyJWK, getPrivateKeyJWK} from "./components/CryptoUtils"
import {getKeys, getOriginalKeys, setKeys} from "./components/KeysStorage";
import {useNavigate} from "react-router-dom";


export function Account() {
    const {user} = useContext(UserContext)
    const [friendKey, setFriendKey] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        fetch("/api/friendKey/get/").then(res => res.text()).then(id => {
            setFriendKey(id)
        })
    }, []);

    function loginToTheNewClientStep2() {
        (async () => {
            show_loading()
            const peer = new Peer()
            const {privateKey, publicKey} = await getOriginalKeys(user)
            const keysData = {
                publicKeyJWK: JSON.stringify(publicKey),
                wrappedPrivateKey: privateKey
            }
            let anotherPublicKey
            peer.on("open", (id) => {
                fetch(`/api/createLoginChat/?peerID=${id}`).then((res) => res.text()).then((id) => {
                    loading_remove()
                    dialog({
                        headline: "Log in to the new client",
                        description: "Enter the following verification code on your new device:",
                        body: `<h1 style="text-align: center;margin-bottom: 0">${id}</h1>`,
                        actions: [
                            {
                                text: "OK",
                            }
                        ]
                    })
                })
                peer.on("connection", (conn) => {
                    conn.on("data", async (data) => {
                        if (data.type === "EPK") {
                            anotherPublicKey = await getPublicKey(data.data)
                            conn.send({
                                type: "KEYS",
                                data: await encrypt(JSON.stringify(keysData), anotherPublicKey)
                            })
                        } else {
                            conn.close()
                            peer.disconnect()
                        }
                    })
                })
            })
        })()
    }

    function loginToTheNewClient() {
        dialog({
            headline: "Log in to the new client",
            description: "Make sure the new client is logged in with the same account as this client and click the \"Continue\" button",
            actions: [
                {
                    text: "Cancel",
                },
                {
                    text: "Continue",
                    onClick: () => {
                        loginToTheNewClientStep2()
                        console.log("confirmed")
                    },
                }
            ]
        })
    }

    function editFriendKey() {
        prompt({
            headline: "Edit Friend Key",
            description: "Enter a new friend Key",
            confirmText: "OK",
            cancelText: "Cancel",
            onConfirm: (value) => {
                fetch(`/api/friendKey/set/?friendKey=${value}`)
                setFriendKey(value)
            }
        })
    }

    return <div className={"container"}>
        <ReplyChatTitle>Account</ReplyChatTitle>
        <mdui-top-app-bar style={{position: "relative", paddingInline: "0"}} class={"mobile"} variant="small">
            <mdui-dropdown trigger="hover">
                <mdui-button-icon icon="menu" slot={"trigger"} style={{marginRight: "8px"}}></mdui-button-icon>
                <mdui-menu>
                    <mdui-menu-item onClick={() => navigate("/logout/")}>Log out</mdui-menu-item>
                </mdui-menu>
            </mdui-dropdown>
            <mdui-top-app-bar-title>Reply Chat</mdui-top-app-bar-title>
            <div style={{flexGrow: 1}}></div>
        </mdui-top-app-bar>
        <div style={{display: "flex"}}>
            <div style={{width: "100%"}}>
                <h2>@{user}</h2>
                <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                    <p>Friend Key: {friendKey}</p>
                    <mdui-button-icon icon={"edit"} onClick={editFriendKey}></mdui-button-icon>
                </div>
                <mdui-button full-width onClick={loginToTheNewClient} style={{width: "max-content"}}>Login to the new
                    client
                </mdui-button>
            </div>
            <mdui-avatar style={{minWidth: "100px", height: "100px", margin: "12px"}}>{user[0]}</mdui-avatar>
        </div>
    </div>
}