import React, {createContext, useContext, useState} from 'react'
import './index.css'
import App from './App'
import {createRoot} from "react-dom/client"
import {BrowserRouter, Navigate, useNavigate, Outlet} from "react-router-dom"
import {Routes, Route} from "react-router"

import {AddFriends, Chat, ChatSkeleton, ChatWindow} from "./pages/chat";
import {Account} from "./pages/account";
import {Live, CreateLiveRoom, JoinLiveRoom} from "./pages/live";
import {Login, Logout, Rights} from './pages/login';

import 'mdui'
import 'mdui/mdui.css'
import {setTheme, setColorScheme} from 'mdui'
import {ReplyChatTitle} from "./pages/components/components";

setColorScheme('#68548E')
setTheme("auto")

window.login_state = "undefined";
export const UserContext = createContext("undefined");
export const DisplayContext = createContext("undefined");

export const ProtectedRoute = ({redirectPath = '/login'}) => {
    const navigate = useNavigate();
    const {setUser} = useContext(UserContext);

    if (window.login_state === "guest") {
        return <Navigate to={redirectPath} replace/>;
    } else if (window.login_state === "undefined") {
        fetch(`/api/login_state/`).then(r => r.text()).then(r => {
            if (r === "failed") {
                window.login_state = "guest"
                setUser("guest")
                return navigate(redirectPath);
            } else {
                window.login_state = r
                setUser(r)
            }
        })
    }
    return <Outlet/>;
}

export const UserControl = () => {
    const [user, setUser] = useState("undefined");
    return <UserContext.Provider value={{user, setUser}}><Outlet/></UserContext.Provider>;
}


const root = createRoot(document.getElementById("root"));

root.render(<BrowserRouter>
    <Routes>
        <Route element={<UserControl/>}>
            <Route element={<ProtectedRoute/>}>
                <Route element={<App/>}>
                    <Route path="/" element={<Chat/>}>
                        <Route index element={<ChatSkeleton/>}/>
                        <Route path={"/chat/addFriend/"} element={<AddFriends/>}/>
                        <Route path={"/chat/:friendChatID"} element={<ChatWindow/>}/>
                    </Route>
                    {/*<Route path="/" element={<Navigate to={"/live/"} replace={true}/>}/>*/}
                    <Route path={"/live"} element={<><ReplyChatTitle>Live</ReplyChatTitle><Outlet/></>}>
                        <Route index element={<Live/>}/>
                        <Route path="CLR" element={<CreateLiveRoom/>}/>
                        <Route path=":anotherID" element={<JoinLiveRoom/>}/>
                    </Route>
                    <Route path="/account" element={<Account/>}/>
                    {/*<Route path="/account" element={<Navigate to={"/live/"} replace={true}/>}/>*/}
                </Route>
            </Route>
            <Route element={<Rights/>}>
                <Route path={"logout"} element={<Logout/>}/>
                <Route path={"login"} element={<Login/>}/>
            </Route>
        </Route>
    </Routes>
</BrowserRouter>);