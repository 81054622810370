import {$} from 'mdui'
import {useState} from "react";
import "./components.css"

export function show_loading() {
    $("body").append(`<div id="loading" style="opacity: 0">
    <mdui-circular-progress></mdui-circular-progress>
    </div>`)
    $("#loading").css("opacity", 1)
}

export function loading_remove() {
    $("#loading").css("opacity", 0)
    setTimeout(() => {
        $("#loading").remove()
    }, 500)
}


export function show_background(container, onClick) {
    $(container).append(`<div id="background" style="opacity: 0">
    </div>`)
    $("#background").on("click", onClick)
    $("#background").css("opacity", 1)
}

export function background_remove() {
    $("#background").css("opacity", 0)
    setTimeout(() => {
        $("#background").remove()
    }, 500)
}


export function Message(props) {
    var right_state
    if (props.right) {
        right_state = " right"
    } else {
        right_state = ""
    }
    return <div className={"bubble-group" + right_state}>
        <div>
            <div className={"bubble"}>
                <span>{props.content}</span>
            </div>
        </div>
    </div>
}

export function ChatPanel(props) {
    const [cont, setCont] = useState("")

    function send() {
        props.onSend(cont)
        setCont("")
    }

    return <div className={"chat-panel"}>
        <div className={"panel"}>
            <input placeholder={"Message..."} onKeyDown={(e) => {
                if (e.keyCode === 13) send()
            }} onInput={(e) => setCont(e.target.value)} value={cont}/>
            <mdui-button onClick={send}>Send</mdui-button>
        </div>
    </div>
}


export function ReplyChatTitle({children}) {
    return <title>{children} • Reply Chat</title>
}