import CryptoJS from "crypto-js"


export function splitArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks
}

export function mergedUint8Array(arrays) {
    return arrays.reduce((accumulator, currentValue) => {
        return new Uint8Array([...accumulator, ...currentValue]);
    }, new Uint8Array())
}

export function md5(string) {
    const hash = CryptoJS.MD5(string);
    return hash.toString(CryptoJS.enc.Hex);
}

export async function genKey() {
    const keys = await window.crypto.subtle.generateKey(
        {
            name: "RSA-OAEP",
            modulusLength: 2048,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: "SHA-256",
        },
        true,
        ["encrypt", "decrypt"],
    )
    return [keys.privateKey, keys.publicKey]
}

export async function getPublicKey(publicKeyJWK) {
    return await window.crypto.subtle.importKey("jwk", publicKeyJWK, {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256",
    }, true, ["encrypt"])
}

export async function getPublicKeyJWK(publicKey) {
    return await window.crypto.subtle.exportKey(
        "jwk",
        publicKey
    )
}

export async function getPrivateKey(privateKeyJWK) {
    return await window.crypto.subtle.importKey("jwk", privateKeyJWK, {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256",
    }, true, ["decrypt"])
}

export async function getPrivateKeyJWK(privateKey) {
    return await window.crypto.subtle.exportKey(
        "jwk",
        privateKey
    )
}

export function ArrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    for (var len = bytes.byteLength, i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export function base64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export async function encrypt(string, publicKey) {
    const uint8DataArrays = splitArray((new TextEncoder).encode(string), 150)
    const b64DataArrays = []
    for (let i = 0; i < uint8DataArrays.length; i++) {
        b64DataArrays.push(ArrayBufferToBase64(await window.crypto.subtle.encrypt(
            {
                name: "RSA-OAEP",
            },
            publicKey,
            uint8DataArrays[i],
        )))
    }
    return b64DataArrays.join("-")
}


export async function decrypt(data, privateKey) {
    const splitedB64 = data.split("-")
    const uint8DataArrays = []
    for (let i = 0; i < splitedB64.length; i++) {
        uint8DataArrays.push(new Uint8Array(await window.crypto.subtle.decrypt(
            {name: "RSA-OAEP"},
            privateKey,
            base64ToUint8Array(splitedB64[i]),
        )))
    }
    return (new TextDecoder).decode(mergedUint8Array(uint8DataArrays))
}

export async function asyncMap(array, asyncCallback) {
    const result = await Promise.all(array.map(async (item) => {
        return await asyncCallback(item);
    }));
    return result;
}