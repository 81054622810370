import iconLightOutlined from './image/icon-light-outlined.png'
import iconDarkOutlined from './image/icon-dark-outlined.png'
import {Outlet} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {observeResize, breakpoint} from 'mdui'
import {DisplayContext} from "./index";


export default function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const [display, setDisplay] = useState("undefined");

    const syncNav = (route) => {
        // route = route.split("/").join("/")
        route = "/" + route.split("/")[1] + "/"
        if (route === "//") route = "/chat/"
        // if (!route.endsWith("/")) {
        //     route += "/"
        // }
        document.querySelector('#app-nav-bar').value = route
        document.querySelector('#app-nav-rail').value = route
    }

    const nav = (route) => {
        syncNav(route)
        navigate(route)
    }

    useEffect(() => {
        syncNav(location.pathname)
    }, [location]);

    useEffect(() => {
        observeResize(document.body, function (entry, observer) {
            const breakpointCondition = breakpoint();
            if (breakpointCondition.down('md')) {
                document.querySelector("#app-nav-bar").style.display = "flex"
                document.querySelector("#app-nav-rail").style.display = "none"
                setDisplay("mobile")
            } else {
                document.querySelector("#app-nav-bar").style.display = "none"
                document.querySelector("#app-nav-rail").style.display = "flex"
                setDisplay("desktop")
            }
        });
    }, []);

    return <mdui-layout>
        <mdui-navigation-rail id={"app-nav-rail"} class={"desktop"} alignment={"center"} value={"/chat/"}>
            <img className={"light"} slot={"top"} src={iconLightOutlined} alt={""}
                 style={{width: "50px", height: "50px"}}/>
            <img className={"dark"} slot={"top"} src={iconDarkOutlined} alt={""}
                 style={{width: "50px", height: "50px"}}/>
            <mdui-navigation-rail-item value={"/chat/"} onClick={() => nav('/')}>
                <mdui-icon slot={"icon"}>chat</mdui-icon>
                Chat
            </mdui-navigation-rail-item>
            <mdui-navigation-rail-item value={"/live/"} onClick={() => nav('/live/')}>
                <mdui-icon slot={"icon"}>stream</mdui-icon>
                Live
            </mdui-navigation-rail-item>
            <mdui-navigation-rail-item value={"/account/"} onClick={() => nav('/account/')}>
                <mdui-icon slot={"icon"}>person</mdui-icon>
                Account
            </mdui-navigation-rail-item>
            <mdui-dropdown trigger="hover" slot={"bottom"}>
                <mdui-button-icon icon="menu" slot={"trigger"}></mdui-button-icon>
                <mdui-menu>
                    <mdui-menu-item onClick={() => navigate("/logout/")}>Log out</mdui-menu-item>
                </mdui-menu>
            </mdui-dropdown>
        </mdui-navigation-rail>
        <mdui-layout-main>
            <div>
                <div id={"screen"}>
                    <DisplayContext.Provider value={{display}}>
                        <Outlet/>
                    </DisplayContext.Provider>
                </div>
            </div>
        </mdui-layout-main>
        <mdui-navigation-bar id={"app-nav-bar"} class={"mobile"} value={"/chat/"}>
            <mdui-navigation-bar-item value={"/chat/"} onClick={() => nav('/')}>
                <mdui-icon slot={"icon"}>chat</mdui-icon>
                Chat
            </mdui-navigation-bar-item>
            <mdui-navigation-bar-item value={"/live/"} onClick={() => nav('/live/')}>
                <mdui-icon slot={"icon"}>stream</mdui-icon>
                Live
            </mdui-navigation-bar-item>
            <mdui-navigation-bar-item value={"/account/"} onClick={() => nav('/account/')}>
                <mdui-icon slot={"icon"}>person</mdui-icon>
                Account
            </mdui-navigation-bar-item>
        </mdui-navigation-bar>
    </mdui-layout>
}