import {
    background_remove, loading_remove, ReplyChatTitle, show_background, show_loading
} from "./components/components";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import {getKeys, getOriginalKeys, setKeys, setOriginalKeys} from "./components/KeysStorage";
import {DisplayContext, UserContext} from "../index";
import {$, alert, dialog, prompt} from "mdui";
import {
    decrypt, encrypt, genKey, getPrivateKeyJWK, getPublicKey, getPublicKeyJWK, asyncMap, getPrivateKey
} from "./components/CryptoUtils";
import {Peer} from "peerjs";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import './styles/messages.css'

import iconLightOutlined from '../image/icon-light-outlined.png'
import iconDarkOutlined from '../image/icon-dark-outlined.png'

import Pusher from 'pusher-js'


const FriendsContext = createContext()

export function Message(props) {
    console.log(props.reply)
    const menu = useRef(null)
    var right_state, timeStart, timeEnd, time, menu_state;
    if (props.right) {
        right_state = " right"
    } else {
        right_state = ""
    }

    function getTimeNow() {
        var now = new Date();
        return now.getTime();
    }

    function holdDown(e) {
        if (e.button === 2) {
            return
        }
        timeStart = getTimeNow();
        time = setInterval(function () {
            timeEnd = getTimeNow();
            if (timeEnd - timeStart > 1000) {
                clearInterval(time);
                openMenu()
            }
        }, 100);
    }

    function holdUp() {
        clearInterval(time);
    }

    function openMenu() {
        if (menu_state) {
            return
        }
        $(menu.current).parent().parent().css("z-index", "99")
        show_background(".message-chat-window>div", closeMenu)
        $(menu.current).css("height", "auto")
        $(menu.current).css("animation", ".2s ease-out menu_popout")
        $(menu.current).css("opacity", "1")
        setTimeout(() => {
            $(menu.current).css("animation", "none")
        }, 200)
        menu_state = true
    }

    function closeMenu() {
        background_remove()
        $(menu.current).css("animation", ".2s ease-in menu_popout reverse")
        $(menu.current).css("opacity", "0")
        setTimeout(() => {
            $(menu.current).css("animation", "none")
            $(menu.current).css("height", "0")
            $(menu.current).parent().parent().css("z-index", "unset")
        }, 200)
        menu_state = false
    }

    function deleteMessage() {
        closeMenu()
        fetch("/api/deleteMessage/?messageID=" + props.messageID)
    }

    return <div className={"bubble-group" + right_state}>
        <div>
            {props.reply.length !== 0 ?
                <span style={{
                    fontSize: "12px",
                }}><span>{props.reply[0].from}</span> : <span>{props.reply[0].data}</span></span> :
                <></>
            }
            <div className={"bubble"} onMouseDown={holdDown} onMouseUp={holdUp} onTouchStart={holdDown}
                 onTouchEnd={holdUp} onContextMenu={(e) => {
                openMenu();
                e.preventDefault()
            }}>
                <span>{props.content}</span>
            </div>
            <span className={"time"}>{props.time}</span>
        </div>
        <div className={"menu"}>
            <mdui-menu ref={menu}>
                <mdui-menu-item onClick={() => {
                    closeMenu();
                    props.onReply(props.messageID)
                }}>Reply
                </mdui-menu-item>
                {props.right ? <mdui-menu-item onClick={deleteMessage}>Delete</mdui-menu-item> : <></>}
            </mdui-menu>
        </div>
    </div>
}

export function ChatPanel(props) {
    const [cont, setCont] = useState("")
    const [encryption, setEncryption] = useState(true)
    const encryptionSwitchRef = useRef(null);

    useEffect(() => {
        encryptionSwitchRef.current.addEventListener('change', (e) => {
            console.log(e.target.checked)
            if (e.target.checked) {
                setEncryption(true)
            } else {
                setEncryption(false)
            }
        });

    }, []);

    function send() {
        props.onSend(cont, encryption)
        setCont("")
    }

    return <div className={"message-chat-panel"}>
        <div className={"message-panel"}>
            <input placeholder={"Message..."} onKeyDown={(e) => {
                if (e.keyCode === 13) send()
            }} onInput={(e) => setCont(e.target.value)} value={cont}/>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <mdui-tooltip content="RSA Encyption">
                    <mdui-switch unchecked-icon="remove_moderator" checked-icon="verified_user"
                                 ref={encryptionSwitchRef} checked={true}></mdui-switch>
                </mdui-tooltip>
                <mdui-button variant={"text"} onClick={send}>Send</mdui-button>
            </div>
        </div>
    </div>
}

function UserItem(props) {

    return <div name={props.name} className={"useritem"} onClick={props.onClick} {...props}>
        <mdui-avatar>{props.name[0]}</mdui-avatar>
        <div>
            <p className={"username"}>{props.name}</p>
        </div>
        {props.moreButton ? <mdui-button-icon icon={"more_vert"}></mdui-button-icon> : <></>}
    </div>
}

export function Chat() {
    const {user} = useContext(UserContext)
    const {display} = useContext(DisplayContext)
    const [friends, setFriends] = useState([])
    const navigate = useNavigate()

    function loginTheNewClient() {
        prompt({
            headline: "Login to the new client",
            description: "Go to the account page on the old client where you stored or generated the keys, click the \"Log in to the new client\" button and follow the prompts",
            confirmText: "OK",
            cancelText: "Cancel",
            onConfirm: (value) => {
                show_loading()
                fetch(`/api/checkLoginPin/?chatID=${value}`).then(res => res.text()).then(peid => {
                    if (peid === "false") {
                        alert({
                            description: "Invalid PIN", confirmText: "OK"
                        })
                    } else {
                        (async () => {
                            const [privateKey, publicKey] = await genKey()
                            const publicKeyJWK = await getPublicKeyJWK(publicKey)
                            const peer = new Peer()
                            peer.on("open", (id) => {
                                const conn = peer.connect(peid)
                                conn.on("open", () => {
                                    console.log("connected")
                                    conn.on("data", async (data) => {
                                        if (data.type === "KEYS") {
                                            decrypt(data.data, privateKey).then(message => {
                                                const keys = JSON.parse(message)
                                                setOriginalKeys(user, JSON.parse(keys.publicKeyJWK), keys.wrappedPrivateKey)
                                                loading_remove()
                                                conn.send({type: "OK"})
                                                conn.close()
                                                peer.disconnect()
                                            })
                                        }
                                    })
                                    conn.send({type: "EPK", data: publicKeyJWK})
                                })
                            })
                        })()
                    }
                })
            },
            onCancel: () => console.log("canceled"),
        })
    }

    useEffect(() => {
        if (user !== "undefined") {
            fetch("/api/getFriends/").then(res => res.json()).then(data => {
                for (let i = 0; i < data.length; i++) {
                    data[i].name = data[i].users.filter(item => item !== user).join(",")
                }
                setFriends(data)
            })
        }
    }, [user])

    useEffect(() => {
        if (user !== "undefined") {
            (async () => {
                const results = await getOriginalKeys(user)
                if (results === null) {
                    dialog({
                        headline: "Initialize Reply Chat", stackedActions: true, actions: [{
                            text: "First time using this account", onClick: () => {
                                (async () => {
                                    const [privateKey, publicKey] = await genKey()
                                    setKeys(user, await getPublicKeyJWK(publicKey), await getPrivateKeyJWK(privateKey))
                                })()
                            }
                        }, {
                            text: "Log in to the new client", onClick: () => {
                                loginTheNewClient()
                            }
                        }]
                    })
                } else {
                    console.log("keys loaded", getKeys(user))
                    window.keys = await getKeys(user)
                }
            })()
        }
    }, [user]);

    const selectUser = (id) => {
        const urls = window.location.href.split("/")
        let lastUrl
        if (urls[urls.length - 1] === "") {
            lastUrl = urls[urls.length - 2]
        } else {
            lastUrl = urls[urls.length - 1]
        }
        document.querySelectorAll("div[class*='useritem']").forEach((k) => {
            k.classList.remove("selected")
        })
        if (display === "desktop") {
            if (lastUrl !== id) {
                navigate(`/chat/${id}`)
                document.querySelector(".message-chat-window").classList.add("expand")
                document.querySelector(`div.useritem[id='${id}']`).classList.add("selected")
            } else {
                navigate("/")
            }
        } else {
            navigate(`/chat/${id}`)
            document.querySelector(".message-chat-window").classList.add("expand")
        }
    }

    return <div className={"message-chat-screen"}>
        <div className={"message-chat-drawer"}>
            <mdui-top-app-bar class={"mobile chat-app-bar"} variant={"large"} scroll-behavior={"shrink"}
                              scroll-threshold={"60"}
                              scroll-target={".message-userlist"}>
                <mdui-top-app-bar-title>Reply Chat</mdui-top-app-bar-title>
            </mdui-top-app-bar>
            <div className={"message-userlist"}>
                <div className={"useritem"} id={"addFriend"} onClick={() => selectUser("addFriend")}>
                    <mdui-avatar icon={"add"}></mdui-avatar>
                    <div>
                        <p className={"username"}>Add new friends</p>
                    </div>
                </div>
                {friends.map((friend, index) => <UserItem key={index} name={friend.name} id={friend._id}
                                                          moreButton={true} onClick={(e) => {
                    selectUser(friend._id)
                }}/>)}
            </div>
        </div>
        <div className={"message-chat-window"}>
            <FriendsContext.Provider value={{friends}}>
                <Outlet/>
            </FriendsContext.Provider>
        </div>
    </div>
}

export function ChatSkeleton() {
    return <>
        <ReplyChatTitle>Home</ReplyChatTitle>
        <div  className={"message-chat-window-div"} style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
        }}>
            <img className={"light"} src={iconLightOutlined} alt={""}
                 style={{width: "72px", height: "72px", marginBottom: "4px"}}/>
            <img className={"dark"} src={iconDarkOutlined} alt={""}
                 style={{width: "72px", height: "72px", marginBottom: "4px"}}/>
            <h1 style={{margin: "0"}}>Reply Chat</h1>
            <h3 style={{margin: "0"}}>Send private messages to your friends</h3>
        </div>
    </>
}

export function AddFriends() {
    const accountRef = useRef(null);
    const friendKeyRef = useRef(null);
    const [account, setAccount] = useState("");
    const [friendKey, setFriendKey] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        accountRef.current.addEventListener('input', (e) => {
            setAccount(e.target.value)
        });
        friendKeyRef.current.addEventListener('input', (e) => {
            setFriendKey(e.target.value)
        });
    }, []);

    function addFriend() {
        fetch(`/api/addFriend/${account}/${friendKey}`).then(res => res.text()).then(data => {
            if (data === "ok") {
                alert({
                    headline: "Success", description: "Friend request sent", confirmText: "OK", onConfirm: () => {
                        navigate(0)
                    }
                })
            } else {
                alert({
                    headline: "Error", description: data, confirmText: "OK"
                })
            }
        })
    }

    return <>
        <ReplyChatTitle>Add friends</ReplyChatTitle>
        <div className={"message-chat-window-div mobile"} style={{padding: "12px", height: "auto"}}>
            <mdui-top-app-bar class={"message-app-bar"} style={{margin: "0", width: "100%"}}>
                <mdui-button-icon icon={"arrow_back"} onClick={() => {
                    document.querySelector(".message-chat-window").classList.remove("expand")
                }}></mdui-button-icon>
                <mdui-top-app-bar-title>Add friend</mdui-top-app-bar-title>
            </mdui-top-app-bar>
        </div>
        <div
            className={"message-chat-window-div"}
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "8px"
            }}>
            <h2 style={{margin: "0"}}>Add Friends</h2>
            <mdui-text-field label={"Account"} ref={accountRef} value={account}></mdui-text-field>
            <mdui-text-field label={"Friend Key"} ref={friendKeyRef} value={friendKey}></mdui-text-field>
            <mdui-button onClick={addFriend}>Add</mdui-button>
        </div>
    </>
}

export function ChatWindow() {
    const {friendChatID} = useParams();
    const {friends} = useContext(FriendsContext)
    const {user} = useContext(UserContext)
    const navigate = useNavigate();
    const [messages, setMessages] = useState([])
    const [publicKeys, setPublicKeys] = useState({})
    const [replyMessageID, setReplyMessageID] = useState(null)


    const replyMessage = messages.filter(item => item.id === replyMessageID)[0]
    const info = friends.find(item => item._id === friendChatID)

    async function dataProcessor(item) {
        if (item.encryption) {
            return {
                reply: item.reply,
                from: item.from,
                right: item.from === user,
                data: await decrypt(item.data[user], await getPrivateKey(window.keys.privateKey)),
                time: new Date(item.time).toLocaleString(),
                id: item["_id"]
            }
        }
        return {
            reply: item.reply,
            from: item.from,
            right: item.from === user,
            data: item.data[0],
            time: new Date(item.time).toLocaleString(),
            id: item["_id"]
        }
    }

    useEffect(() => {
        if (info !== undefined) {
            fetch("/api/getMessages/" + info._id + "/0").then(res => res.json()).then(async data => {
                setMessages(await asyncMap(data, dataProcessor))
            })

            fetch("/api/getChatPublicKeys/" + info._id).then(res => res.json()).then(data => {
                setPublicKeys(data)
            })

            var pusherClient = new Pusher('282c44de01d272aadb5e', {
                cluster: 'ap1'
            });

            const channel = pusherClient.subscribe(info._id);
            channel.bind('sendMessage', function (data) {
                (async () => {
                    const processedData = await dataProcessor(data)
                    setMessages(prevMessages => [...prevMessages, processedData])
                })()
            });
            channel.bind('deleteMessage', function (data) {
                setMessages(prevMessages => prevMessages.filter(item => item.id !== data.id))
            });

        }
    }, []);

    if (info !== undefined) {
        if (info.requests.indexOf(user) !== -1) {
            return <div className={"message-chat-window-div"}>
                <ReplyChatTitle>@{info?.name}</ReplyChatTitle>
                <mdui-top-app-bar class={"message-app-bar mobile"}>
                    <mdui-button-icon icon={"arrow_back"} onClick={() => {
                        document.querySelector(".message-chat-window").classList.remove("expand")
                    }}></mdui-button-icon>
                    <mdui-avatar>{info === undefined ? "undefined" : info.name[0]}</mdui-avatar>
                    <mdui-top-app-bar-title>{info?.name}</mdui-top-app-bar-title>
                </mdui-top-app-bar>
                <div className={"request-card"}>
                    <h2>@{info?.name}</h2>
                    <p>sent you a friend request</p>
                    <mdui-button variant={"tonal"} style={{marginBottom: "4px"}} onClick={() => {
                        fetch(`/api/rejectFriend/${info._id}`).then(() => {
                            navigate('/')
                        })
                    }}>Reject
                    </mdui-button>
                    <mdui-button variant={"filled"} onClick={() => {
                        fetch(`/api/acceptFriend/${info._id}`).then(() => {
                            navigate(0)
                        })
                    }}>Accept
                    </mdui-button>
                </div>
            </div>
        } else if (info.requests.length !== 0) {
            return <div className={"message-chat-window-div"}>
                <ReplyChatTitle>@{info?.name}</ReplyChatTitle>
                <mdui-top-app-bar class={"message-app-bar mobile"}>
                    <mdui-button-icon icon={"arrow_back"} onClick={() => {
                        document.querySelector(".message-chat-window").classList.remove("expand")
                    }}></mdui-button-icon>
                    <mdui-avatar>{info === undefined ? "undefined" : info.name[0]}</mdui-avatar>
                    <mdui-top-app-bar-title>{info?.name}</mdui-top-app-bar-title>
                </mdui-top-app-bar>
                <div className={"request-card"}>
                    <p>Wait for <h2>@{info?.requests[0]}</h2> to accept</p>
                </div>
            </div>
        }
    }

    function sendMessage(message, encryption) {
        (async () => {
            if (encryption) {
                fetch("/api/sendMessage/", {
                    method: "POST", headers: {
                        "Content-Type": "application/json"
                    }, body: JSON.stringify({
                        encryption: true, data: {
                            [user]: await encrypt(message, await getPublicKey(publicKeys[user])),
                            [info.name]: await encrypt(message, await getPublicKey(publicKeys[info.name]))
                        }, reply: replyMessageID, chatID: info._id
                    })
                })
            } else {
                fetch("/api/sendMessage/", {
                    method: "POST", headers: {
                        "Content-Type": "application/json"
                    }, body: JSON.stringify({
                        encryption: false, data: [message], reply: replyMessageID, chatID: info._id
                    })
                })
            }
        })()
    }


    return <div className={"message-chat-window-div"}>
        <ReplyChatTitle>@{info?.name}</ReplyChatTitle>
        <mdui-top-app-bar class={"message-app-bar mobile"}>
            <mdui-button-icon icon={"arrow_back"} onClick={() => {
                document.querySelector(".message-chat-window").classList.remove("expand")
            }}></mdui-button-icon>
            <mdui-avatar>{info === undefined ? "undefined" : info.name[0]}</mdui-avatar>
            <mdui-top-app-bar-title>{info?.name}</mdui-top-app-bar-title>
        </mdui-top-app-bar>
        <div className={"message-messages-list"}>
            {messages.map((data, index) => {
                if (data !== undefined) {
                    if (data.right) {
                        return <Message key={index} reply={messages.filter(item => item.id === data.reply)}
                                        messageID={data.id} content={data.data}
                                        time={data.time} onReply={setReplyMessageID} right/>
                    } else {
                        return <Message key={index} reply={messages.filter(item => item.id === data.reply)}
                                        messageID={data.id} content={data.data}
                                        time={data.time} onReply={setReplyMessageID}/>
                    }
                }
            })}
        </div>
        <mdui-divider/>
        <div style={{
            transition: "var(--mdui-motion-duration-long2)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            overflow: 'hidden',
            padding: replyMessageID === null ? "0" : "8px",
            minHeight: replyMessageID === null ? "0" : "40px",
            maxHeight: replyMessageID === null ? "0" : "40px",
        }}>
            <div>
                <span style={{display: "block", fontSize: "10px"}}>Reply</span>
                <span>{replyMessage?.from}:{replyMessage?.data}</span>
            </div>
            <mdui-button-icon onClick={() => setReplyMessageID(null)} icon={"close"}></mdui-button-icon>
        </div>
        <ChatPanel onSend={sendMessage}/>
    </div>
}